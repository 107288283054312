.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 1.5rem !important;
  font-size: 12px;
  padding: 0px !important;
}
.MuiOutlinedInput-root {
  height: 2rem !important;
  font-size: 12px;
  padding: 5px !important;
}

.MuiTablePagination-root {
  min-height: 30px !important;
}
.MuiTablePagination-toolbar {
  min-height: 30px !important;
}
.primaryButton {
  color: white !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}
button.secondaryButton {
  background: transparent !important;
  border: 1px solid #d6d8dc !important;
  border-radius: 6px !important;
  color: #313131 !important;
  box-shadow: none !important;
}
.filterFields .MuiInputBase-root.MuiOutlinedInput-root {
  height: 30px !important;
  min-width: 80px;
  padding-left: 8px !important;
  .MuiSelect-select {
    font-size: 14px;
    padding-left: 0;
  }
}

.customDropdowm .MuiPopover-paper {
  top: 65px !important;
  background: #ffffff !important;
  border-radius: 12px !important;
  ul.MuiMenu-list {
    min-width: 400px;
    padding: 20px 0;
    max-width: 400px;
    li {
      gap: 10px;
      padding: 8px 20px;
      .MuiListItemIcon-root {
        min-width: 16px;
      }
    }
  }
}
.jobCard {
  padding: 20px 16px;
  background: #ffffff;
  box-shadow: 0px 5px 28px rgba(95, 90, 90, 0.1);
  border-radius: 12px;
}
.expiredCard {
  background: #dcd6d6;
  border: 1px solid #f39640 !important;
  box-shadow: 0px 5px 28px rgba(95, 90, 90, 0.1);
  border-radius: 12px;
}
.premiumCard {
  background: #ffffff;
  border: 1px solid #f39640 !important;
  box-shadow: 0px 5px 28px rgba(95, 90, 90, 0.1);
  border-radius: 12px;
}
.premiumUser {
  position: absolute;
  right: -1px;
  top: 0;
  font-size: 9px;
  padding: 1.5px 10px;
  background-color: #f39640;
  border-radius: 0px 12px;
  color: #fff;
}
.disputeTag {
  position: absolute;
  right: -1px;
  top: 0;
  font-size: 9px;
  padding: 3px 11px;
  background-color: #c63a3a;
  border-radius: 0px 12px;
  color: #fff;
  line-height: 1;
}
.recommendTag {
  color: #c63a3a;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  padding: 0 6px;
  height: 18px;
  border: 1px solid #c63a3a;
  background: rgba(198, 58, 58, 0.08);
  border-radius: 12px;
}
.customWidth {
  width: 800px;
  max-width: 100%;
}
.customFieldInput {
  border-width: 1px !important;
}
.customFieldInput:disabled {
  background-color: #f1f3f4 !important;
}
.selectFieldInput {
  .customSelectField {
    fieldset {
      border-color: #d6d8dc;
      height: 40px;
      top: 0 !important;
      legend {
        display: none;
      }
    }
  }
}
.customRadio {
  margin: 0 !important;
  gap: 4px;
  padding: 12px 8px;
  border: 1px solid #d6d8dc;
  border-radius: 6px;
  height: 40px;
  flex: 1;
  .MuiButtonBase-root {
    padding: 0 !important;
    svg {
      width: 16px !important;
      color: #d6d8dc;
    }
  }
  .MuiButtonBase-root.Mui-checked {
    svg {
      color: #1650e2;
    }
  }
  .MuiFormControlLabel-label {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #313131;
  }
}
textarea.customTextarea {
  padding: 10px 8px;
  min-height: 90px;
}
.formSection.autoComplete .MuiInputBase-formControl {
  min-height: 40px !important;
  border-radius: 6px;
  column-gap: 8px;
  padding: 8px !important;
  max-height: initial !important;
  height: auto !important;
  row-gap: 8px;
  fieldset {
    border-width: 1px !important;
  }
  .MuiInputAdornment-positionStart {
    padding-left: 4px;
    svg {
      color: #a9a8a8;
      width: 16px !important;
      height: 16px !important;
    }
  }
}
.formSection.autoComplete .MuiInputBase-formControl > input {
  font-size: 13px;
  // padding: 0 50px 0 30px !important;
  //TO DO write custom style
  padding: 0 !important;
}
.formSection.autoComplete .MuiAutocomplete-tag {
  margin: 0px !important;
  height: 24px;
  font-size: 12px;
  font-weight: 500;
}
.customFormDialog .MuiDialog-paper.MuiDialog-paperScrollPaper {
  max-width: 800px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 0px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 100px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #a9a8a8;
}
.dividerSection {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    background: #d6d8dc;
    width: 1px;
  }
}
.customSwitch .MuiSwitch-switchBase + span.MuiSwitch-track {
  background-color: #d3d6da !important;
}
.customSwitch .MuiSwitch-switchBase.Mui-checked + span.MuiSwitch-track {
  background-color: #1890ff !important;
}
.customSwitch .MuiSwitch-switchBase {
  left: 3px !important;
  top: 1px;
}

.customSwitch .MuiSwitch-switchBase.Mui-checked {
  left: -1px !important;
}
.mainScreen {
  padding-top: 64px;
}
.filterHead + .mainScreen {
  padding-top: 118px;
}
.tabShadow {
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.07));
}
.css-12rl710-MuiPaper-root-MuiDialog-paper {
  overflow-y: unset !important;
}
.tagStyle {
  display: inline-block;
  position: relative;
  line-height: normal;
  height: 18px;
  margin: 0 8px;
  .tag {
    line-height: 18px;
    position: relative;
    display: inline-block;
    top: -5px;
  }
  &:before {
    content: "";
    position: absolute;
    left: -8px;
    height: 18px;
    width: 8px;
    background: rgba(51, 51, 51, 0.08);
    border: 1px solid #d6d8dc;
    top: -1px;
    border-right: 0;
    border-top-left-radius: 11px;
    border-bottom-left-radius: 3px;
  }
  &:after {
    content: "";
    position: absolute;
    right: -8px;
    height: 18px;
    width: 8px;
    background: rgba(51, 51, 51, 0.08);
    border: 1px solid #d6d8dc;
    top: -1px;
    border-left: 0;
    border-bottom-right-radius: 11px;
    border-top-right-radius: 3px;
  }
}
.textViewList {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #313131;
  padding-right: 10px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    right: -2px;
    top: 8px;
    background: #313131;
    width: 4px;
    height: 4px;
    border-radius: 100%;
  }
  &:last-child:before {
    background-color: transparent;
  }
}
.textViewDashList {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #313131;
  padding-right: 10px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    right: -2px;
    top: 8px;
    background: #313131;
    width: 6px;
    height: 2px;
  }
  &:last-child:before {
    background-color: transparent;
  }
}
.tagStyle.primaryTag:before {
  border-color: #1650e2;
  background: rgba(22, 80, 226, 0.08);
}

.tagStyle.primaryTag:after {
  border-color: #1650e2;
  background: rgba(22, 80, 226, 0.08);
}
.tagStyle.warningTag:before {
  border-color: #c63a3a;
  background: rgba(198, 58, 58, 0.08);
}

.tagStyle.warningTag:after {
  border-color: #c63a3a;
  background: rgba(198, 58, 58, 0.08);
}
.tagStyle.successTag:before {
  border-color: #299957;
  background: rgba(41, 153, 87, 0.08);
}

.tagStyle.successTag:after {
  border-color: #299957;
  background: rgba(41, 153, 87, 0.08);
}
.tagStyle.normalTag:after {
  border-color: #d6d8dc;
  background: rgba(41, 153, 87, 0.08);
}

.headerAutocomplete .autoComplete {
  min-width: 300px;
}
.jobDotList {
  position: relative;
  padding-right: 10px;
  &:before {
    content: "";
    position: absolute;
    right: -2px;
    top: 6px;
    background: #1650e2;
    width: 4px;
    height: 4px;
    border-radius: 100%;
  }
  &:last-child:before {
    background-color: transparent;
  }
}
.jobCard:hover {
  background: #f7f7f7;
  border: 1px solid rgba(51, 51, 51, 0.08);
  box-shadow: 0px 5px 28px rgba(95, 90, 90, 0);
  transition: all 0.2s ease;
}

.jobCard:active,
.jobCard:focus {
  background: rgba(22, 80, 226, 0.06) !important;
  border: 1px solid rgba(22, 80, 226, 0.06) !important;
  box-shadow: 0px 5px 28px rgba(95, 90, 90, 0) !important;
  transition: all 0.2s ease;
}
.jobCard.premiumCard:hover {
  background: #f7f7f7 !important;
  box-shadow: 0px 5px 28px rgba(95, 90, 90, 0) !important;
  transition: all 0.2s ease;
}

.jobCard.premiumCard:focus,
.jobCard.premiumCard:active {
  background: rgba(22, 80, 226, 0.06) !important;
  border: 1px solid rgba(22, 80, 226, 0.06) !important;
  box-shadow: 0px 5px 28px rgba(95, 90, 90, 0) !important;
  transition: all 0.2s ease;
}

.jobCard {
  transition: all 0.32s ease;
  border: 1px solid transparent;
}
.reviewBlock:last-child {
  border-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.customPagination ul.MuiPagination-ul {
  button.MuiPaginationItem-previousNext {
    border: 1px solid #d6d8dc;
  }
  button.MuiPaginationItem-page {
    font-size: 12px;
    font-weight: 700;
    border: 0;
  }
  button.Mui-selected.MuiPaginationItem-page {
    border: 1px solid #d6d8dc;
    background: transparent;
  }
  button {
    min-width: 30px;
    height: 30px;
    border-radius: 6px;
  }
}
.selectFieldInput .selectWithTextField .customSelectField > .MuiSelect-select {
  padding-left: 5px !important;
  padding-right: 18px !important;
}

.selectWithTextField {
  display: flex;
}

.selectFieldInput .selectWithTextField .customSelectField {
  max-width: 70px;
  /* border-right: 1px solid #d6d8dc !important; */
  border-radius: 0 !important;
}

.selectWithTextField .customFieldInput {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.selectWithTextField .selectWithinput_customSelect__zZW\+J fieldset {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 0;
}

.selectWithTextField
  .selectWithinput_customSelect__zZW\+J.Mui-focused
  fieldset {
  border-color: #d6d8dc;
}

.selectWithTextField .selectWithinput_customSelect__zZW\+J:hover fieldset {
  border-color: #d6d8dc;
}

.selectWithTextField .customFieldInput:hover,
.selectWithTextField .customFieldInput:focus {
  border-color: #d6d8dc;
}
.customDatePicker .MuiInputBase-formControl {
  height: 2.5rem !important;
  border-radius: 0.375rem;
  border-width: 0px;
  --tw-border-opacity: 1;
  border-color: rgb(214 216 220 / var(--tw-border-opacity));
  padding: 0.5rem !important;
  font-size: 13px;
  button.MuiButtonBase-root {
    margin-right: 0 !important;
    padding: 0;
    svg {
      width: 18px;
    }
  }
}

.customDatePicker .MuiInputBase-formControl > input {
  padding: 0 !important;
}
.chooseProfileModal .MuiDialogContent-root {
  overflow: initial;
}

.chooseProfileModal .MuiDialog-paperScrollPaper {
  overflow: inherit;
}
.mdWidthModal .MuiDialog-container > .MuiDialog-paperScrollPaper {
  max-width: 800px;
}
.filterHead + div {
  padding-top: 54px;
}
.filterHeight {
  min-height: calc(100vh - 64px);
  max-height: calc(100vh - 64px);
}
.customCheckbox label {
  display: flex;
  gap: 4px;
  margin: 0;
  padding: 4px 0;
}

.customCheckbox label > span.MuiButtonBase-root {
  padding: 0;
}
.customCheckbox .MuiFormControlLabel-label {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #313131;
}
.featuresBox {
  .featuresBoxItem {
    border-bottom: 1px solid rgba(51, 51, 51, 0.08);
    &:last-child {
      border-bottom: 0;
    }
  }
}
.tooltipBtn {
  background: transparent !important;
  text-transform: none !important;
  padding: 0 !important;
}
.borderItem {
  padding: 0 12px;
  border-right: 1px solid #d6d8dc;
  &:last-child {
    border-right: 0;
  }
}
button.btnActive {
  background-color: #dbe5fc !important;
}
.customTab {
  background: #edeff1;
  border-radius: 6px;
  padding: 4px;
  .customTabItem {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #313131;
    padding: 5px 10px;
    border-radius: 6px;
    transition: all 0.2s ease;
    outline: none !important;
  }
}

.customTab .customTabItem[aria-selected="true"] {
  transition: all 0.2s ease;
  background: #fff;
}
.customDatePicker .MuiInputBase-formControl.Mui-error fieldset,
.customDatePicker .MuiInputBase-formControl fieldset {
  border-color: #d6d8dc;
  border-width: 1px;
}

.customDatePicker .dateError .MuiInputBase-formControl fieldset,
.customDatePicker .dateError .MuiInputBase-formControl:hover fieldset {
  // border-color: #d32f2f;
  border-width: 1px;
}

.customDatePicker .MuiInputBase-formControl:hover fieldset {
  border-color: #313131;
  border-width: 1px;
}

.customDatePicker .MuiInputBase-formControl.Mui-focused fieldset {
  border-color: rgb(22, 80, 226);
  border-width: 1px;
}
.formSection.autoComplete.mapAutoComplete .MuiInputBase-formControl input {
  padding-left: 5px !important;
}
.headerSearch input.customFieldInput {
  padding-right: 55px !important;
}
span.cameraIcon {
  position: absolute;
  left: 5px;
  bottom: 3px;
  width: auto;
  transition: all 0.2s ease;
  opacity: 0;
}

.profileBox:hover .cameraIcon {
  opacity: 1;
  transition: all 0.2s ease;
}

.customTable .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  box-shadow: none !important;
  border: 1px solid #d6d8dc;
  border-radius: 4px;
}

.customTable .MuiToolbar-root.MuiToolbar-gutters {
  padding: 0 !important;
}

.customTable {
  thead tr th {
    background: rgba(22, 80, 226, 0.06);
    border-left: 1px solid #d6d8dc !important;
    border-bottom: 1px solid rgba(51, 51, 51, 0.08);
    color: #313131;
    padding: 10px 12px !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 16px !important;
  }
  tbody tr td {
    border: 0;
    border-left: 1px solid #d6d8dc !important;
    border-bottom: 1px solid rgba(51, 51, 51, 0.08) !important;
    color: #585858;
    padding: 16px 12px !important;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
}

.customTable tbody tr td:first-child {
  border-left: 0 !important;
}

.customTable tbody tr:last-child td {
  border-bottom: 0 !important;
}
.customTable thead tr th:first-child {
  border-left: 0 !important;
}
.customDatePicker .MuiInputBase-formControl > input::placeholder {
  text-transform: uppercase;
}
// .App[dir="rtl"] .headerSearch > div {
//   flex-direction: row-reverse;
// }

.App[dir="rtl"] .headerSearch > div form button svg {
  right: auto !important;
  left: 8px;
}

.App[dir="rtl"] .headerSearch > div form input.customFieldInput {
  padding-left: 55px !important;
  padding-right: 8px !important;
}
.MuiPaper-rounded.MuiMenu-paper {
  background: #ffffff;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  min-width: 200px;
  max-width: 396px !important;
}

.MuiPaper-rounded.MuiMenu-paper ul.MuiMenu-list {
  padding: 8px;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  max-height: 370px;
  overflow-y: auto;
}

.MuiPaper-rounded.MuiMenu-paper ul.MuiMenu-list li.MuiMenuItem-root {
  padding: 8px;
  border-radius: 8px;
  white-space: break-spaces;
  width: 100%;
}

.MuiPaper-rounded.MuiMenu-paper ul.MuiMenu-list hr.MuiDivider-root {
  margin: 2px 0;
}
.MuiAutocomplete-popper {
  .MuiPaper-rounded {
    background: #ffffff !important;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15) !important;
    border-radius: 8px !important;
    ul.MuiAutocomplete-listbox {
      padding: 8px;
      display: flex;
      flex-direction: column;
      li.MuiAutocomplete-option {
        font-size: 0.875rem !important;
        padding: 6px 12px !important;
        border-radius: 8px;
        min-height: 36px;
      }
    }
  }
}
.dotBlock {
  line-height: 20px !important;
  position: relative;
  display: inline-block;
  padding: 0 4px;
}

.dotBlock:before {
  content: "";
  position: absolute;
  right: 2px;
  top: 8px;
  background: #313131;
  width: 4px;
  height: 4px;
  border-radius: 100%;
}
.selectFieldInput .customSelectField > .MuiSelect-select {
  padding-left: 5px;
}
.formSection.autoComplete .startAdornment + div .MuiInputBase-formControl {
  padding-left: 26px !important;
}
.pac-container {
  z-index: 9999;
}
.selectFieldInput .customSelectField.Mui-error fieldset {
  border-color: #d6d8dc !important;
}
.customDatePicker .MuiInputBase-formControl > input::placeholder {
  font-weight: 600;
}
.MuiPaper-rounded.MuiMenu-paper ul.MuiMenu-list > ul {
  width: 100%;
}
.customDropdowm.mobileDropdown .MuiPopover-paper {
  min-width: 100% !important;
  max-width: 100% !important;
  left: 0 !important;
  top: 55px !important;
  border-radius: 0 !important;
  min-height: calc(100% - 110px);
}
.customDropdowm.mobileDropdown .MuiPopover-paper ul.MuiMenu-list {
  max-width: 100%;
}
span.MuiLinearProgress-root.MuiLinearProgress-indeterminate {
  position: fixed;
  top: 0;
  width: 100%;
}
.navTabs ul {
  max-width: 100%;
  overflow-x: auto;
}

.navTabs ul li {
  display: flex;
  flex: none;
}

.navTabs ul::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}
label.radioBtnText .MuiTypography-root {
  font-size: 13px;
  font-weight: 500;
}
.MuiPaper-rounded.MuiMenu-paper ul.MuiMenu-list li.MuiMenuItem-root {
  min-height: auto;
}
.filterFields.relative.filterBlock > .filterBlock {
  padding: 0;
  border-bottom: 0;
}

button.btnView {
  font-size: 12px !important;
  height: 26px !important;
}
.MuiAutocomplete-popper {
  .MuiAutocomplete-paper {
    .MuiAutocomplete-listbox {
      li.MuiAutocomplete-option {
        font-size: 14px !important;
      }
    }
  }
}
.MuiTooltip-tooltip {
  padding: 8px 16px !important;
  h4 {
    font-size: 12px !important;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }
}
.MuiTooltip-tooltip > div {
  gap: 0px !important ;
}
th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader.sticky {
  z-index: 50;
  background: #f1f5fd;
}

.formSection.autoComplete .MuiInputBase-formControl {
  padding-right: 65px !important;
}
[dir="rtl"]
  .formSection.autoComplete
  .startAdornment
  + div
  .MuiInputBase-formControl {
  padding-right: 26px !important;
  padding-left: 65px !important;
}

[dir="rtl"]
  .formSection.autoComplete
  .startAdornment
  + div
  .MuiInputBase-formControl
  .MuiAutocomplete-endAdornment {
  right: auto;
  left: 8px;
}

[dir="rtl"] .formSection.autoComplete .startAdornment {
  left: auto;
  right: 0.5rem;
  transform: rotateY(180deg);
}
.commonModalBlock {
  max-height: calc(100vh - 32px);
}

@media (max-width: 768px) {
  .MuiDialog-container .MuiDialog-paper.MuiDialog-paperScrollPaper {
    margin: 16px !important;
    max-height: 100% !important;
  }
  .mainScreen {
    padding-top: 56px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border-radius: 0px;
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 8px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 100px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: #a9a8a8;
  }
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-l3ln04-MuiAutocomplete-root
    .MuiOutlinedInput-root {
    padding-right: 56px !important;
  }
  .customDropdowm .MuiPopover-paper ul.MuiMenu-list {
    min-width: 100%;
  }
  button.primaryButton,
  button.secondaryButton,
  button.bg-primary,
  button.bg-View {
    font-size: 12px !important;
    padding: 0 10px !important;
    height: 40px !important;
  }
  button.acceptBtn {
    padding-right: 28px !important;
  }
  button.btnView {
    height: 26px !important;
  }
  .MuiPaper-rounded.MuiMenu-paper {
    max-width: calc(100% - 64px) !important;
    // left: 32px !important;
    // min-width: calc(100% - 64px) !important;
  }
  .formSection.autoComplete .MuiInputBase-formControl {
    input {
      height: initial !important;
    }
  }
}
@media (max-width: 1200px) {
  .customWidth {
    width: 100%;
  }
}
@media (max-width: 1099px) {
  .filterHeight {
    min-height: calc(100vh - 111px);
    max-height: calc(100vh - 111px);
  }
}
@media (max-width: 2000px) {
  .dialogoverflow .MuiDialog-container > .MuiDialog-paper {
    margin: 0 16px !important;
    max-height: calc(100vh - 32px) !important;
  }
}
@media (max-width: 768px) {
  .dialogoverflow .MuiDialog-container > .MuiDialog-paper {
    max-height: calc(100% - 32px) !important;
  }
  .commonModalBlock {
    max-height: calc(100% - 32px) !important;
    height: calc(100% - 32px) !important;
    overflow-y: auto;
  }
}
@media (min-width: 1199px) {
  .filterDropdownMenu {
    min-width: 250px;
    max-width: 100%;
  }
}
@media (min-width: 899px) {
  .modalLarge.dialogoverflow .MuiDialog-container > .MuiDialog-paper {
    overflow: visible;
  }
  .modalLarge.dialogoverflow
    .MuiDialog-container
    > .MuiDialog-paper
    .MuiDialogContent-root {
    overflow: visible !important;
  }
}
.autoComplete .MuiAutocomplete-listbox {
  max-height: 370px;
}
