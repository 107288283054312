.fixedInputClass {
  @apply rounded-md appearance-none h-10 relative block w-full px-3 py-2 border border-formBorder   focus:outline-none   focus:z-10 text-xs lg:text-sm;
}
.fixedNumInput {
  @apply rounded-md appearance-none h-10 relative block w-full px-3 py-2 pl-[60px] border border-formBorder   focus:outline-none   focus:z-10 sm:text-sm;
}
.numAdornment {
  @apply absolute h-10 w-[50px] p-2 border-r border-formBorder rounded-l-md z-50 text-sm text-placeholderText flex items-center justify-center;
}
.customCard {
  @apply bg-bgWhite rounded-lg;
}
.labelText {
  @apply text-xs font-semibold text-[#43474F] mb-1.5;
}
.customFieldInput {
  @apply !h-10 border-0 border-formBorder p-2 text-[13px] font-medium rounded-md focus:border-primary hover:border-primaryText focus:outline-none;
}
.selectFieldInput .customSelectField {
  @apply !h-10 !border-0 !border-formBorder !text-[13px] !font-medium !rounded-md;
}
.startAdornment {
  @apply absolute left-2 top-3;
}
.startAdornment svg {
  @apply text-[#A9A8A8];
}
.detailsLabel {
  @apply text-detailLabel flex items-center gap-1;
}
.skillsTag {
  @apply border border-skillTag text-skillTag text-sm font-medium p-1.5 rounded-md;
}
.educationCardList > .experienceCard {
  @apply pb-4 border-b border-formBorder;
}
.educationCardList > .experienceCard:last-child {
  @apply pb-0 border-b-0;
}
.mediaList {
  @apply border-b border-formBorder p-2 pb-4;
}
.mediaList:last-child {
  @apply pb-0 border-b-0;
}
.portfolioList {
  @apply border-b border-formBorder p-2 px-0;
}
.portfolioList:last-child {
  @apply pb-0 border-b-0;
}
.jobTags {
  @apply bg-taggreyBg text-xs text-textColor font-medium py-[3px] px-1.5 rounded-md;
}
.borderBlock {
  @apply border-r border-formBorder;
}
.borderBlock:last-child {
  @apply border-r-0;
}
.uploadButton {
  @apply bg-primary text-bgWhite h-10 min-w-[72px] text-sm normal-case p-2.5 rounded-md cursor-pointer;
}
.logoImg {
  @apply h-28 md:h-24 w-auto max-w-full mx-auto;
}
