@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #edeff1;
}
body,
body * {
  font-family: "Manrope", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.text-7xl.banner-heading {
  font-size: 3.75rem !important;
  @media only screen and (max-width: 1099.98px) {
    font-size: 3.5rem !important;
  }
}
.mobile-text-view {
  @media (max-width: 399px) {
    font-size: 25px !important;
  }
}
.contact-us-button {
  background-color: #f8f9fa !important;
  color: #000000 !important;
}
.quote-img {
  height: 500px;
  padding-left: 20px;
  object-fit: cover;
  object-position: top center;
}
