.label {
  .labelText {
    margin-bottom: 8px;
    display: inline-block;
  }
}

.textSmall {
  font-size: 14px !important;
  padding: 6px 12px;
}
.inputGroup {
  width: 100%;
  .customSelect {
    fieldset {
      border: 1px solid #585858;
      border-radius: 6px;
      border-width: 1px !important;
    }
  }
}
