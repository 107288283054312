.element {
  height: 1000px;
  background-color: #ededed;
  font-size: 45px;
  padding-top: 55px;
  padding-left: 10px;
}

.element.no-padding {
  padding-top: 0;
}

.tabLink.active {
  color: #1650e2 !important;
  border-bottom: 2px solid #1650e2 !important;
}
.tabLink {
  cursor: pointer;
}
